/* eslint-disable camelcase */
// vue set 
const baseUrl = process.env.NODE_ENV === 'production' ? './' : '/admin/' ; 

const dev = {
  showLicense:true ,
  showSideNav:true,
  showApiTitle:false,
  usePHPMenu:true,
  useDotNetMenu:false
};

const config = { 
  restApiDriver: 'phpApiUrl', // default api driver 
  dotnetApiDriver: 'dotnetApiUrl',
  baseUrl,
  pathQueryMode:'router', // get or router
  //pathQueryMode:'get', 
  clientWebUrl : 'https://uat-ntccm.oceanlighttech.com',
};

const defaultYearCode = {
  num: '111',
};

// restApiDriver 
const restApiDriver = {
  defaultApiUrl : 'https://uat-ntccm.oceanlighttech.com/AdminApi/api' ,
  dotnetApiUrl  : 'https://uat-ntccm.oceanlighttech.com:5080/api' ,
  phpApiUrl  : 'https://uat-ntccm.oceanlighttech.com/AdminApi/api' ,
  tiny_mce_wiris : 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
};

const modulesList = {
  ssoSignIn: false,
};

var googleMapScaleValues = [{
    val: 2,
    dspVal: '2 公尺'
  }, {
    val: 5,
    dspVal: '5 公尺'
  },
  {
    val: 10,
    dspVal: '10 公尺'
  },
  {
    val: 20,
    dspVal: '20 公尺'
  },
  {
    val: 50,
    dspVal: '50 公尺'
  },
  {
    val: 100,
    dspVal: '100 公尺'
  },
  {
    val: 200,
    dspVal: '200 公尺'
  },
  {
    val: 500,
    dspVal: '500 公尺'
  },
  {
    val: 1000,
    dspVal: '1 公里'
  },
  {
    val: 2000,
    dspVal: '2 公里'
  },
  {
    val: 5000,
    dspVal: '5 公里'
  },
  {
    val: 10000,
    dspVal: '10 公里'
  },
  {
    val: 20000,
    dspVal: '20 公里'
  },
  {
    val: 50000,
    dspVal: '50 公里'
  },
  {
    val: 100000,
    dspVal: '100 公里'
  },
  {
    val: 200000,
    dspVal: '200 公里'
  },
  {
    val: 500000,
    dspVal: '500 公里'
  },
  {
    val: 1000000,
    dspVal: '1000 公里'
  },
  {
    val: 2000000,
    dspVal: '2000 公里'
  },
  {
    val: 5000000,
    dspVal: '5000 公里'
  }
];

var loginType = {
  general: 'general',
  facebook: 'facebook',
  google: 'google',
  appleid: 'appleid',
  email: 'email',
  phone: 'phone',
  sso: 'sso',
}

const userIconConfig = {
  'domain' : 'uat-ntccm.oceanlighttech.com',
  'protocol' : 'http://',
  'upload_path' : '/AdminApi/storage/images/users/icon/',
  'name': 'user_icon',
};

const reviewImageConfig = {
  'domain' : 'uat-ntccm.oceanlighttech.com',
  'protocol' : 'http://',
  'upload_path' : '/AdminApi/storage/images/review/',
  'name': 'review',
};

const imageConfig = {
  'domain' : 'uat-ntccm.oceanlighttech.com',
  'protocol' : 'https://',
  'upload_path' : '/AdminApi/storage/images/',
};


  const ssoConfig = {
    'domain' : 'sbm.test.ntpc.edu.tw',
    'protocol' : 'http://',
    'path' : '/SingleSignOn/',
    'aesKey': 'nhuiasxinyasxinydongbidongbinhui',
    'aesIv': 'tgvfrjmnbyhpoilk',
    'logout': 'logout.php',
  };

  const exportFile = {
    'domain' : 'uat-ntccm.oceanlighttech.com',
    'protocol' : 'https://',
    'file_path' : '/AdminApi/storage/file',
    'privacy' : '校園平面繪圖系統_隱私權與智財權政策說明_11207版.pdf',
    'task' : '任務模組教學手冊_新北市政府校園平面繪圖系統.pdf',
    'operation_manual' : '校園平面繪圖系統操作手冊.pdf',
    'guide_manual' : '校園平面繪圖系統_首次使用導引手冊.pdf',
  };

export { config, dev , restApiDriver , defaultYearCode, modulesList ,googleMapScaleValues, loginType, userIconConfig, reviewImageConfig , imageConfig, ssoConfig ,exportFile};
